
(function () {

	'use strict';

	function getMissingFeatures() {
		var missing = [];

		// extremely old features first, just in case...

		// JSON methods https://caniuse.com/json
		if (!window.JSON || typeof window.JSON.parse !== 'function') {
			missing.push('JSON');
		}

		// FormData https://caniuse.com/mdn-api_formdata
		if (!window.FormData) {
			missing.push('FormData');
		}

		// history.pushState https://caniuse.com/mdn-api_history_pushstate
		if (!window.history || !window.history.pushState) {
			missing.push('historyAPI');
		}

		// let keyword https://caniuse.com/let
		var supportsLet = false;
		try {
			eval("let test1;");
			supportsLet = true;
		} catch (e) {
			supportsLet = false;
		}
		if (!supportsLet) {
			missing.push('let');
		}

		// Proxy object https://caniuse.com/proxy
		if (!window.Proxy) {
			missing.push('Proxy');
		}

		// spread operator https://caniuse.com/mdn-javascript_operators_spread
		var supportsSpread = false;
		try {
			eval("var test2 = {...{}};");
			supportsSpread = true;
		} catch (e) {
			supportsSpread = false;
		}
		if (!supportsSpread) {
			missing.push('spread');
		}

		// Promise.all https://caniuse.com/mdn-javascript_builtins_promise_all
		if (!window.Promise || !window.Promise.all) {
			missing.push('Promise.all');
		}

		// UNSOLVED - regex that throws error (Erik tv)
		// error https://app.rollbar.com/a/dbox-staging/fix/item/developX/5754#occurrences
		// try https://github.com/databox/webapp/pull/6145/commits/b849e2623b76082680714b07533a31d32576fca8

		// for testing
		// missing.push('test');

		return missing;
	}

	function logDeprecation(missingFeatures) {

		var xhr = new XMLHttpRequest();
		xhr.open('POST', '/deprecation', true);
		xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
		xhr.send(JSON.stringify({
			page: window.dsCurrentPage, // defined as global variable in deprecation-screen.ejs
			loc_href: window.location.href,
			missingFeatures: missingFeatures,
			userAgent: navigator.userAgent
		}));
	}

	var deprecatedCt1 = document.getElementById('deprecated'),
		body = document.getElementsByTagName('body')[0];

	body.style.backgroundColor = '';
	if (deprecatedCt1) {
		deprecatedCt1.className = 'd-none';
	}

	var missingFeatures = getMissingFeatures();

	if (missingFeatures.length) {

		body.style.backgroundColor = '#f76868';
		if (deprecatedCt1) {
			deprecatedCt1.className = '';
		}

		logDeprecation(missingFeatures);

		// must set timeout because otherwise the xmlhttprequest will be cancelled before sent
		setTimeout(function() {
			try {
				window.stop();
			} catch (e) {
				// skip
			}
		}, 1);
	}

}());
